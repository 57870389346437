<template>
  <div
    v-if="inArchive.length > 0"
    style="
      background-color: #f9f9f9;
      box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
    "
  >
    <table id="archive-table-afe1fc26">
      <tr>
        <th width="5%" style="padding-left: 19px"></th>
        <th width="50%" style="padding-left: 10px"></th>
        <th width="35%"></th>
        <th width="10%"></th>
      </tr>
      <tr class="pr" v-for="project in inArchive" :key="project.projectId">
        <td>
          <div class="align-center d-flex">
            <Images
              :name="project.platform + '.svg'"
              style="margin-left: 16px"
            />
          </div>
        </td>
        <td style="padding-left: 10px" class="d-md-table-cell d-sm-none d-none">
          <Texting type="p1 primColor">{{ project.projectTitle }}</Texting>
        </td>
        <td style="padding-left: 10px" class="d-md-none d-none d-sm-table-cell">
          <Texting type="p1 primColor">{{
            project.projectTitle.slice(0, 20) + "..."
          }}</Texting>
        </td>
        <td style="padding-left: 10px" class="d-md-none d-sm-none d-table-cell">
          <Texting type="p1 primColor">{{
            project.projectTitle.slice(0, 6) + "..."
          }}</Texting>
        </td>
        <td style="vertical-align: center">
          <router-link
            v-if="project.hasActivated"
            :to="{
              name: 'MyRewards',
              query: { archive: 'true' },
              params: { projectTitle: project.projectTitle },
            }"
            type="p1bold grey10"
            class="text_decor"
          >
            <Texting
              id="archive-perks-history-text-d9959a14"
              class="pointer"
              type="p1bold grey10"
              >View Perk's History</Texting
            >
          </router-link>
        </td>
        <td style="vertical-align: center">
          <div class="d-flex justify-end align-center">
            <div class="d-flex">
              <!--<ProjectConfig class="d-flex" :is-archive="true" :project="project"/>-->
              <CampaignConfiguration
                class="d-flex"
                :is-archive="true"
                :project="project"
              />
            </div>
            <div class="d-flex" style="margin-right: 20px; margin-left: 28px">
              <v-tooltip
                content-class="my-tooltip"
                color="rgba(38,39,40,0.9)"
                max-width="200px"
                top
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <div
                      class="restore-icon d-flex justify-center align-center"
                    >
                      <Images
                        id="archive-restore-icon-4f33c89a"
                        name="restore_icon.svg"
                        class="pointer"
                        @click.native="sendToProjects(project.projectId)"
                      />
                    </div>
                  </span>
                </template>
                <Texting type="p1n mywhite">Restore</Texting>
                <div class="arrow-tooltip" style="left: 13px"></div>
              </v-tooltip>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <!--  <EmptyState text="You have not projects in your Archive yet." v-else />-->
</template>

<script>
import { mapState } from "vuex";
import Texting from "./Texting";
import Images from "./MyImages";
//import EmptyState from "../EmptyState/EmptyState";
//import ProjectConfig from "../ProjectConfig/ProjectConfig";
import axios from "axios";
import router from "../plugins/router";
import CampaignConfiguration from "@/components/CampaignConfiguration";

export default {
  components: {
    CampaignConfiguration,
    Images,
    Texting,
    //EmptyState,
  },
  computed: {
    ...mapState({
      projects: "projects",
    }),
    inArchive: function () {
      return this.projects.filter((project) => project.inArchive == true);
    },
  },
  mounted() {
    return this.$store.dispatch("getProjects");
  },
  beforeUpdate() {
    if (this.inArchive.length === 1) {
      localStorage.setItem("archive_added_once", "true");
      localStorage.setItem("menu_clicked", "true");
    }
  },
  methods: {
    sendToProjects(id) {
      axios
        .put(`/api/project/inProjects/${id}`)
        .then(() => {
          this.$store.dispatch("getProjects");
          if (this.inArchive.length == 1) {
            router.push({ name: "user" });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.pr {
  border-bottom: 1px solid #f4f4f4;
  background-color: #ffffff;
}
.pr:hover {
  box-shadow: 0 1px 2px rgba(8, 35, 48, 0.24), 0 2px 6px rgba(8, 35, 48, 0.16);
  opacity: 0.95;
}
td {
  padding-top: 23px;
  padding-bottom: 23px;
}
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.restore-icon {
  height: 30px;
  width: 30px;
}
.restore-icon:hover {
  background-color: #f4f4f4;
  border-radius: 50%;
}
</style>
