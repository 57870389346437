<template>
  <v-app v-if="inArchive.length > 0">
    <AppHeaderUser v-if="user" />
    <v-container style="background-color: #f9f9f9; height: 100%" fluid>
      <div class="d-flex align-center py-10 pl-3">
        <div
          :class="{ point: hover }"
          id="archive-back-icon-8052a4e6"
          @click="$router.back()"
          style="height: 35px"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <Images name="arrow.svg" />
        </div>
        <div class="d-flex ml-3">
          <Texting
            id="campaigns-archive-text-a8e713d8"
            @click.native="$router.back()"
            class="pointer"
            type="p1bold grey2"
            >Campaigns Archive</Texting
          >
        </div>
      </div>
      <v-row>
        <v-col cols="12" style="padding: 15px 20px 15px 20px">
          <Archive />
        </v-col>
      </v-row>
    </v-container>
    <AppFooter />
  </v-app>
</template>

<script>
import Texting from "../components/Texting";
import Images from "../components/MyImages";
import { mapState } from "vuex";
import Archive from "../components/Archive";
import AppHeaderUser from "../components/AppHeaderUser";
import AppFooter from "../components/AppFooter";
export default {
  components: {
    Archive,
    Images,
    Texting,
    AppHeaderUser,
    AppFooter,
  },
  data: () => ({
    hover: false,
  }),
  computed: {
    ...mapState({
      projects: "projects",
      user: "user",
    }),
    inArchive: function () {
      return this.projects.filter((project) => project.inArchive == true);
    },
  },
  mounted() {
    this.$store.dispatch("getProjects").then(() => {
      if (this.inArchive.length === 0) {
        this.$router.push({ name: "PageNotFound" });
      }
    });
  },
};
</script>

<style scoped></style>
